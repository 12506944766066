<template>
  <LayoutManager>
    <div class="cd-main w-100" id="qa-course-discovery-view">
      <div class="cd-main__div" v-if="search != '' || isSearchActive">
        <b-row class="m-0">
          <b-col>
            <h3 class="cd-main__title" v-html="searchText" />
            <LearningSpacesList v-if="search === ''" />
            <div class="cd-main__header d-flex justify-content-between mt-3">
              <div>
                <h5 class="cd-main__heading" v-if="search === ''">
                  {{ $t("course_discovery.top_learning_programmes") }}
                </h5>
                <p class="cd-main__sub-heading">
                  {{ $t("search_course.results_found", { count: getCount }) }}
                </p>
              </div>
              <div class="align-self-end">
                <LanguageDropDown
                  :options="languages"
                  defaultValue="en"
                  :text="$t('my_profile.language.heading')"
                  @onSelect="
                    e => {
                      filter.language = e.value;
                    }
                  "
                ></LanguageDropDown>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row :class="['m-0', { 'mt-2': filter.language !== 'en' }]">
          <b-col class="p-0">
            <div class="ld-sec__tabs mt-0">
              <b-row class="m-0">
                <div class="d-block w-100">
                  <pagination
                    :isLoadMore="!isCoursesLoading && isLoadMore"
                    @onLoadMore="loadMore(true)"
                  >
                    <template v-slot:course-card>
                      <div class="ld-sec__grid-layout">
                        <div
                          class="cd-list--cards"
                          v-for="(course, index) in allCourses"
                          :key="index"
                        >
                          <CourseCard
                            :courseImage="
                              course.image_url
                                ? course.image_url
                                : require('@/assets/images/illustration/course-illustration.svg')
                            "
                            :org="course.org"
                            :courseTitle="course.title"
                            :language="language"
                            :pacing="course.pacing"
                            :courseLanguage="course.language"
                            :course="course"
                            :index="`course-card-${index}`"
                            @click="onCard(course)"
                            @orgClick="onOrgClick(course)"
                          ></CourseCard>
                        </div>
                      </div>
                    </template>
                    <template v-slot:card-loader>
                      <div
                        class="cd-list__loader"
                        v-if="isCoursesLoading && allCourses.length === 0"
                      >
                        <div
                          class="cd-list__loader--div"
                          v-for="c in [1, 2, 3, 4]"
                          :key="c"
                        >
                          <shimmer variant="landing-course-card" />
                        </div>
                      </div>
                    </template>
                  </pagination>
                  <div
                    class="
                      tab-coming-soon
                      d-flex
                      align-items-center
                      justify-content-center
                    "
                    v-if="allCourses.length == 0 && !isCoursesLoading"
                  >
                    <div class="text-center">
                      <img
                        src="@/assets/images/not-found/no-results-found.svg"
                        alt="no result"
                        width="auto"
                        height="auto"
                      />
                      <p class="mt-3 mb-1">{{ $t("search.empty.title") }}</p>
                      <p>
                        {{ $t("search.empty.subtitle") }}
                      </p>
                    </div>
                  </div>
                </div>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <b-row
          class="m-0"
          v-if="search !== '' && allSemanticallySimilarCourses.length > 0"
        >
          <b-col>
            <h3 class="cd-main__title">
              {{ $t("search.showing_top_semantically_similar_results") }}
            </h3>
          </b-col>
        </b-row>
        <b-row
          :class="['m-0', { 'mt-4': filter.language !== 'en' }]"
          v-if="allSemanticallySimilarCourses.length > 0"
        >
          <b-col class="p-0">
            <div class="ld-sec__tabs">
              <b-row class="m-0">
                <div class="d-block w-100">
                  <pagination :isLoadMore="false" @onLoadMore="() => null">
                    <template v-slot:course-card>
                      <div class="ld-sec__grid-layout">
                        <div
                          class="cd-list--cards"
                          v-for="(course,
                          index) in allSemanticallySimilarCourses"
                          :key="index"
                        >
                          <CourseCard
                            :courseImage="
                              course.image_url
                                ? course.image_url
                                : require('@/assets/images/illustration/course-illustration.svg')
                            "
                            :org="course.org"
                            :courseTitle="course.title"
                            :language="language"
                            :pacing="course.pacing"
                            :courseLanguage="course.language"
                            :course="course"
                            :index="`course-card-${index}`"
                            @click="onCard(course)"
                            @orgClick="onOrgClick(course)"
                          ></CourseCard>
                        </div>
                      </div>
                    </template>
                    <template v-slot:card-loader>
                      <div
                        class="cd-list__loader"
                        v-if="isCoursesLoading && allCourses.length === 0"
                      >
                        <div
                          class="cd-list__loader--div"
                          v-for="c in [1, 2, 3, 4]"
                          :key="c"
                        >
                          <shimmer variant="landing-course-card" />
                        </div>
                      </div>
                    </template>
                  </pagination>
                </div>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>
      <LxpLoader v-else />
    </div>
  </LayoutManager>
</template>
<script>
import { mapGetters } from "vuex";
import { LxpLoader } from "didactica";

import getCoursesMixin from "@/components/SearchCourse/mixins/getCourses";
import redirectToLearningSpace from "@/core/mixins/redirectToLearningSpace";
import redirectToCourseDetail from "@/core/mixins/redirectToCourseDetail.js";

import LayoutManager from "../components/Layout/LayoutManager";
import Pagination from "@/components/WrapperComponents/Pagination.vue";
import LearningSpacesList from "@/components/CourseDiscovery/LearningSpacesList.vue";

export default {
  components: {
    LxpLoader,
    LayoutManager,
    Pagination,
    LearningSpacesList
  },
  mixins: [getCoursesMixin, redirectToLearningSpace, redirectToCourseDetail],
  data() {
    return {
      tabs: [
        {
          name: "Learning Programs",
          active: true,
          type: "default",
          tab_id: "lp"
        },
        {
          name: "Modules",
          active: false,
          type: "default",
          tab_id: "mod"
        }
      ],
      isLoading: false,
      tabIndex: 0,
      showFilters: false,
      releaseDateArr: [
        { text: "All", value: "all" },
        { text: "Less than a month ago", value: "less_than_a_month" },
        { text: "More than a month ago", value: "more_than_a_month" }
      ],
      completionTimeArr: [
        {
          text: "1 day",
          code: 1
        },
        {
          text: "1 week",
          code: 2
        },
        {
          text: "1 month",
          code: 3
        },
        {
          text: "3 month",
          code: 4
        },
        {
          text: "6 month",
          code: 5
        },
        {
          text: "1 year",
          code: 6
        }
      ],
      isSearchActive: false,
      roleSearch: "",
      filters: []
    };
  },
  computed: {
    ...mapGetters(["roles", "allConfig", "allLanguage", "language"]),
    isFilterActive() {
      let bool = false;
      this.filters.forEach(fil => {
        if (fil.is_active) {
          bool = true;
        }
      });
      return bool;
    },
    cRoles() {
      let roles = this.roles.map((r, i) => {
        return {
          name: r,
          item: i + 1,
          checked: false
        };
      });
      return roles;
    },
    availableRoles() {
      let arr = [],
        activeInterest = this.cRoles.filter(t => t.checked);
      if (activeInterest.length) {
        arr = activeInterest;
      }
      this.cRoles.forEach(t => {
        if (arr.length < 4 && !t.checked) {
          arr.push(t);
        }
      });
      return arr;
    },
    unselectedRoles() {
      return this.cRoles.filter(
        t =>
          !t.checked &&
          t.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
      );
    },
    releaseData() {
      let data = null;
      if (this.filter.release_date === "all") {
        data = `released_lower_limit=-10000&released_upper_limit=10000`;
      } else if (this.filter.release_date === "less_than_a_month") {
        data = "released_lower_limit=-10000&released_upper_limit=-30";
      } else if (this.filter.release_date === "more_than_a_month") {
        data = "released_lower_limit=-30&released_upper_limit=0";
      }
      return data;
    },
    completionTimeText() {
      let text = "";
      this.completionTimeArr.forEach(c => {
        if (c.code === this.filter.completion_time) {
          text = c.text;
        }
      });
      return text;
    },
    completionData() {
      let data = null;
      if (this.completionTimeText !== "") {
        if (this.completionTimeText.includes("year")) {
          data = this.getInMins("year");
        } else if (this.completionTimeText.includes("month")) {
          data = this.getInMins("month");
        } else if (this.completionTimeText.includes("week")) {
          data = this.getInMins("week");
        } else if (this.completionTimeText.includes("day")) {
          data = this.getInMins("day");
        }
      }
      return data;
    },
    languages() {
      return this.allLanguage.map(l => {
        return {
          text: this.$i18n.t(`data.language.${l.id}`),
          value: l.id
        };
      });
    },
    searchText() {
      if (this.search === "") {
        return this.$t("general.menu.discovery");
      } else {
        return `${this.$t("search.showing_results ")}
              <span class="cd-main__title--search">${this.search}</span>`;
      }
    }
  },
  mounted() {
    this.filters = [
      {
        name: this.$i18n.t("my_profile.language.heading"),
        is_active: true,
        code: "language"
      }
    ];
    this.filter.language = this.language;
    this.showFilters = true;
  },
  beforeDestroy() {
    this.search = "";
  },
  methods: {
    onCard(c) {
      this.navToCourseDetail(c, false);
    },
    onOrgClick(c) {
      this.navigateToLearningSpace(c.org);
    },
    onTabClick(tab) {
      if (tab.id === "lp") {
        this.onSearch(true);
      } else {
        this.showFilters = false;
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
        }, 10);
      }
    },
    activateFilter(f) {
      f.is_active = !f.is_active;
    },
    clearFilters() {
      this.filter.language = this.language;
    },
    onSearchClear() {
      this.isSearchActive = false;
      this.search = "";
      this.onSearch(true);
    },
    onCancel() {
      this.$bvModal.hide("role-modal");
    },
    getInMins(type) {
      let units = {
        year: 24 * 60 * 365,
        month: 24 * 60 * 30,
        week: 24 * 60 * 7,
        day: 24 * 60
      };
      return this.completionTimeText.split(type)[0] * units[type];
    },
    getType() {
      return "enrolled";
    }
  }
};
</script>

<style lang="scss" scoped>
.cd-main {
  text-align: left;
  padding: 24px;
  height: 100%;
  margin: 0 10px;
  .cd-main__search {
    padding: 0 10px;
    display: flex;
    .cd-search__input {
      width: 90%;
      .input-group-prepend {
        .cd-icon__div {
          background: #fff;
          padding: 19px 0 19px 17px;
          display: flex;
          border: 1px solid #e8e8e8;
          border-right: 0;
          border-radius: 4px;

          .cd-search__icon {
            height: auto;
          }
        }
      }
      .form-control {
        padding: 19px 17px;
        border-left: none;
        height: auto;
        border-color: #e8e8e8;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.2px;
        color: #52575c;
        &:focus {
          box-shadow: none;
        }
        &::placeholder {
          font-weight: normal;
        }
      }
      .input-group-append {
        .cd-search__helper {
          background: #fff;
          border: 1px solid #e8e8e8;
          margin-bottom: 0;
          padding: 19px 17px;
          font-size: 12px;
          line-height: 16px;
          display: flex;
          align-items: center;
          text-align: right;
          letter-spacing: 0.2px;
          color: rgba(0, 0, 0, 0.6);
          border-left: none;
          cursor: pointer;
          border-radius: 4px;
          &.active {
            color: #0043ac;
          }
        }
      }
    }
    .cd-main__close {
      padding: 25px;
      border: 1px solid #e8e8e8;
      border-radius: 4px;
      background: #fff;
      align-items: center;
      display: flex;
      cursor: pointer;
      a {
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.2px;
        text-transform: uppercase;
        color: #52575c;
        text-decoration: none;
      }
    }
  }
  .cd-main__title {
    @include h6(500);
    color: $brand-neutral-700;
    margin-bottom: 24px;
    .cd-main__title--search {
      color: $brand-primary;
      font-style: italic;
    }
  }

  .cd-main__header {
    text-align: left;
  }
  .cd-main__heading {
    @include subtitle-regular;
    color: $brand-neutral-900;
    margin: 0 0 8px 0;
  }

  .cd-main__sub-heading {
    color: #919191;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
  .cd-main__div {
    .ld-sec__tabs {
      margin-bottom: 20px;
      margin-right: 20px;
      margin-top: 50px;
      .cd-main__title {
        color: #0043ac;
        font-size: 18px;
        text-align: left;
        margin-bottom: 0;
      }
      .tabs {
        position: relative;
        .nav-tabs {
          width: 99%;
          margin-top: 20px;
          margin-left: 0;
          .nav-item {
            margin-bottom: 20px;
            margin-right: 10px;
            .nav-link {
              text-transform: uppercase;
              font-size: 13px;
              padding: 4px 16px;
              letter-spacing: 0.1px;
            }
          }
        }
      }
      .d-block {
        padding: 0 0 0 15px;
        .ld-sec__grid-layout {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(
            auto-fill,
            minmax(clamp(25% - 24px, 218px, 100%), 1fr)
          );
          gap: 24px;
        }
      }
    }
    .cd-main__filters {
      a {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-align: right;
        letter-spacing: 0.2px;
        color: #000000;
        text-decoration: none;
      }
    }
    .cd-filter__div {
      ul {
        margin-left: 16px;
        list-style: none;
        display: flex;
        position: relative;
        margin-bottom: 35px;
        padding-left: 0;

        &.filter-active {
          border-bottom: 1px solid #eeeeee;
          padding-bottom: 10px;
        }

        li {
          margin-right: 20px;
          div {
            display: flex;
            cursor: pointer;
            &.active {
              background: #e8e8e8;
              border-radius: 50px;
              padding: 1px 10px;
            }

            .cd-filter__title {
              font-weight: 500;
              font-size: 13px;
              line-height: 24px;
              letter-spacing: 0.2px;
              color: #52575c;
              margin-right: 4px;
            }
            img {
              width: 10px;
            }
          }
          .my-filters__div {
            height: 50px;
            width: 100%;
            .filter-div__main {
              position: absolute;
              width: 100%;
              top: 45px;
              display: flex;
              align-items: center;
              left: 0;
              .slider {
                width: 30%;
                //   background: #0043ac;
                //   height: 2px;
              }
              // .slider::-webkit-slider-thumb {
              //   // .slider::-moz-range-thumb {
              //   background: #0043ac;
              // }
              .slider-helper {
                font-weight: 500;
                font-size: 12px;
                line-height: 11px;
                letter-spacing: 0.1px;
                color: #9e9e9e;
                margin-left: 10px;
              }
              .filter-helper {
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                text-align: right;
                letter-spacing: 1.25px;
                text-transform: uppercase;
                color: #0043ac;
                text-decoration: none;
                position: absolute;
                right: 0;
              }
            }
          }
        }
        .cd-filter__clear {
          background: #dceaff;
          padding: 5px 8px;
          border-radius: 50px;
          font-weight: 500;
          font-size: 12px;
          line-height: 12px;
          letter-spacing: 0.1px;
          text-transform: uppercase;
          color: #0043ac;
          display: flex;
          align-items: center;
          height: 22px;
          text-decoration: none;
          cursor: pointer;
          margin-top: 2px;
        }
      }
    }

    .tab-coming-soon {
      height: 350px;
      .text-center {
        width: 50%;
        p {
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          letter-spacing: 0.1px;
          color: #a0a4a8;
        }
      }
    }
  }
}
#role-modal {
  .modal-header {
    border-bottom: 0;
    padding: 23px 25px;
    h5 {
      margin-bottom: 0;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0.2px;
      color: #5d5d5d;
    }
    .ob-btn {
      font-size: 14px;
    }
  }
  .modal-body {
    padding: 0 25px;
    .ta-div {
      background: #ffffff;
      border: 1px solid #e8e8e8;
      border-radius: 4px;
      padding: 5px 0;
      max-height: 350px;
      overflow: auto;
      .input-group {
        border: none;
        padding: 0 10px 5px;
        border-bottom: 1px solid #eeeeee;
        .input-group-text {
          border: none;
          background: transparent;
          display: flex;
          align-items: center;
          padding: 0 10px 0 0;
          .b-icon {
            font-size: 14px;
          }
        }
        .form-control {
          border: none;
          padding: 0;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          letter-spacing: 0.1px;
          color: $vle-primary-text;
          box-shadow: none;
        }
      }
      .ta-div__checkbox {
        padding: 10px 0px;
        .lxp-checkbox__group {
          .custom-control {
            border-bottom: 1px solid #eee;
            padding: 10px 0 10px 2.5rem;
            &:last-child {
              border-bottom: none;
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
  .modal-footer {
    border-top: none;
    padding: 22px;
    justify-content: start;
  }
}

@media screen and (max-width: 1100px) {
  .cd-main {
    .cd-main__div {
      .ld-sec__tabs {
        .d-block {
          .ac__load-more {
            clear: both;
            position: relative;
            top: 10px;
          }
        }
      }
    }
  }
}

// rtl layout
[dir="rtl"] {
  .cd-main {
    .cd-main__header {
      text-align: right;
    }
    .cd-main__div {
      .cd-main__title {
        text-align: right;
      }
      .cd-filter__div {
        ul {
          li {
            margin: 0 0 0 20px;
          }
        }
        .cd-filter__title {
          margin: 0 0 0 4px;
        }
      }
      .ld-sec__tabs {
        margin-left: 20px;
        margin-right: 0;
        .d-block {
          padding: 0 15px 0 0;
          .course-card {
            float: right;
          }
        }
      }
    }
  }
}
</style>
